<template>
  <div>
    <form
      action=""
      method="get"
      class="contact-form"
    >
      <div
        v-for="(form, i) in Forms"
        :key="i"
        class="form-div"
      >
        <label :for="form.id">{{ form.label }}</label>
        <input
          :id="form.id"
          v-model="form.value"
          :type="form.type"
          :name="form.id"
          :placeholder="form.placeholder"
          required
        >
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  props: {
    forms: { type: Array, default: () => [] },
  },
  setup(props) {
    const Forms = ref(props.forms)
    return { Forms }
  },
}
</script>
