<template>
  <div class="e-content">
    <div id="ComponentCharacteristics">
      <h1>Form</h1>
      <p>폼 컴포넌트는 문의를 위한 입력 정보를 전달받습니다.
      </p>
      <b-card title="Component Characteristics">

        <p class="mb-2">컴포넌트로 전달 받는 속성은 다음과 같습니다.</p>
        <h5><span class="font-weight-bold">forms:</span> 폼에 들어갈 각각의 항목에 대한 정보를 배열로 담고 있습니다.</h5><br>
        <div class="ml-2 mb-2">
          <p>id: input 태그의 id 입니다.</p>
          <p>value: 입력값이며 초기값은 null 입니다.</p>
          <p>type: input 태그의 type 입니다.</p>
          <p>name: input 태그의 name 입니다.</p>
          <p>placeholder: input 태그의 placeholder 입니다.</p>
        </div>

        <b-card header-bg-variant="dark" header-text-variant="white" header-font-weight="bold"
                header="props"
                border-variant="dark"
                vertical-align="center">
          <b-card-text class="mt-2">Vue의 모든 컴포넌트 인스턴스에는 각자의 자체 격리된 범위(scope)가 있습니다. 이 때문에 하위 컴포넌트의 템플릿에서는 상위 데이터를 직접 참조할
            수 없는데, props 를 사용하면 하위 컴포넌트로 데이터를 전달할 수 있습니다.
            <br>
            <h5 class="my-2">사용 예시</h5>
            <div class="card-code">
            <pre class="px-2 d-flex align-items-center">
              <code class="language-javascript">
                {{ codePropsSample3 }}
              </code>
            </pre>
            </div>
          </b-card-text>
        </b-card>

        <b-card header-bg-variant="dark" header-text-variant="white" header-font-weight="bold"
                header="Composition API"
                border-variant="dark"
                vertical-align="center">
          <b-card-text class="mt-2">Vue의 기존 option API는 컴포넌트가 커지면서 수많은 데이터가 공존했다면 methods, computed도 그만큼 많아지고, data가 어디서
            어떤 함수에 의해 변하는지 분산되어 추적이 어려워 집니다. 그에 따라 유지보수가 어려워집니다.
            composition API를 사용함으로 setup 함수에 데이터가 그룹핑 되어 보다 용이하게 데이터의 흐름을 파악하고 유지보수와 재사용하기가 용이합니다.
            <br><br>
            <code>setup</code> 함수에 data, method, lifecycle hooks 등을 등록하여 리액트의 훅과 같이 사용할 수 있습니다. <span
                class="font-weight-bold">props</span>와 <span class="font-weight-bold">state</span>로 데이터를 관리합니다.
            <h5 class="my-2">사용 예시</h5>
            <div class="card-code">
            <pre class="px-2 d-flex align-items-center">
              <code class="language-javascript">
                {{ codeCompositionAPI3 }}
              </code>
            </pre>
            </div>
          </b-card-text>
        </b-card>

        <b-card header-bg-variant="dark" header-text-variant="white" header-font-weight="bold"
                header="Form Input Binding"
                border-variant="dark"
                vertical-align="center">
          <b-card-text class="mt-2">v-model 디렉티브를 사용하여 폼 input과 textarea 엘리먼트에 양방향 데이터 바인딩을 생성할 수 있습니다. 입력 유형에 따라 엘리먼트를 업데이트 하는 올바른 방법을 자동으로 선택합니다.
            <br>
            <h5 class="my-2">사용 방법</h5>
            <div class="card-code">
            <pre class="px-2 d-flex align-items-center">
              <code class="language-javascript">
                {{ codeFormInputBinding }}
              </code>
            </pre>
            </div>
          </b-card-text>
        </b-card>
      </b-card>

      <b-card-code id="FormContactUs" title="Form-Contact-Us">
        <p>홈 다섯 번째 섹션에서 사용되는 폼입니다. 문의 내용을 입력받아 텔레그램으로 전송합니다.</p>
        <div class="mt-2 mb-2 d-flex justify-content-center">
          <form-contact-us :forms="forms" />
        </div>
        <b-card-text>
          <span class="font-weight-bold">props:</span> forms="forms"
        </b-card-text>
        <template #code>
          {{ codeForm }}
        </template>
      </b-card-code>
    </div>
  </div>
</template>

<script>
import {BCard, BCardText, BImg} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import {
  codeFormInputBinding,
  codeCompositionAPI3,
  codePropsSample3,
  codeForm,
} from './code'
import FormContactUs from '@/components/Form/FormContactUs.vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'


export default {
  components: {
    FormContactUs,
    BCardCode,
    BCard,
    BCardText,
    BImg,
    Prism,
  },
  data() {
    return {
      rightNavMenu: ['ComponentCharacteristics', 'FormContactUs'],
      codePropsSample3,
      codeCompositionAPI3,
      codeFormInputBinding,
      codeForm,
      forms: [
        {
          id: 'name',
          type: 'text',
          value: null,
          label: 'Name',
          placeholder: 'Enter your name',
        },
        {
          id: 'phone',
          type: 'tel',
          value: null,
          label: 'Mobile Number',
          placeholder: 'Enter your mobile number',
        },
        {
          id: 'corporation',
          type: 'text',
          value: null,
          label: 'Corporation',
          placeholder: 'Where do you belong to?',
        },
        {
          id: 'email',
          type: 'email',
          value: null,
          label: 'Email Address',
          placeholder: 'Enter your email address',
        },
        {
          id: 'message',
          type: 'text',
          value: null,
          label: 'Write your message here',
          placeholder: 'Write your message',
        },
      ],
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_RIGHT_NAV_MENU', this.rightNavMenu)
  },
}
</script>

